<template>
  <div class="con">
    <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
    <div class="desk-right">
      <div class="add-food">
        <div class="ft-32 ft-bold mg-tp40">普通标签</div>
        <div class="flex flex-ac mg-tp25">
          <div class="label-preview-box">
            <div>精品散装饼干</div>
            <div class="mg-tp10">售价：￥15.8/斤</div>
            <div class="mg-tp10" :class="{'color-fff': !palin_produce}">包装日期：2023年11月15日</div>
            <div class="mg-tp10" :class="{'color-fff': !palin_quality}">保质期：60天</div>
            <img class="mg-tp10" src="../../static/images/barcode.png" style="width: 14.15rem;" />
            <div>5231456856321</div>
          </div>
          <div class="label-set-box">
            <div class="label-set-item ft-28 ft-bold">小票内容</div>
            <div class="label-set-item flex flex-ac flex-jsb">
              <span>包装日期</span>
              <van-checkbox v-model="palin_produce" icon-size="3rem" shape="square" @click="handleChange('palin_produce', 1)">显示</van-checkbox>
            </div>
            <div class="label-set-item flex flex-ac flex-jsb">
              <span>保质期</span>
              <van-checkbox v-model="palin_quality" icon-size="3rem" shape="square" @click="handleChange('palin_quality', 2)">显示</van-checkbox>
            </div>
          </div>
        </div>
        <div class="mg-bt30 ft-32 ft-bold mg-tp60">预包装价签（称重商品生效）</div>
        <div class="flex flex-ac mg-tp25">
          <div class="label-preview-box">
            <div>精品散装饼干</div>
            <div class="mg-tp10 flex flex-ac nowrap flex-jsb">
              <div style="flex: 1;">
                <div>单价</div>
                <div class="mg-tp10">￥15.8/斤</div>
              </div>
              <div style="flex: 1;">
                <div>重量</div>
                <div class="mg-tp10">1.765斤</div>
              </div>
            </div>
            <div class="mg-tp10">总价：27.89元</div>
            <div class="mg-tp10" :class="{'color-fff': !befor_produce}">包装日期：2023年11月15日</div>
            <div class="flex flex-ac mg-tp10 nowrap">
              <div class="mg-rt30">
                <img src="../../static/images/barcode.png" style="width: 14.15rem;" />
                <div>5231456856321</div>
              </div>
              <div v-if="befor_quality">
                <div>保质期</div>
                <div class="mg-tp10">60天</div>
              </div>
            </div>
          </div>
          <div class="label-set-box">
            <div class="label-set-item ft-28 ft-bold">小票内容</div>
            <div class="label-set-item flex flex-ac flex-jsb">
              <span>包装日期</span>
              <van-checkbox v-model="befor_produce" icon-size="3rem" shape="square" @click="handleChange('befor_produce', 3)">显示</van-checkbox>
            </div>
            <div class="label-set-item flex flex-ac flex-jsb">
              <span>保质期</span>
              <van-checkbox v-model="befor_quality" icon-size="3rem" shape="square" @click="handleChange('befor_quality', 4)">显示</van-checkbox>
            </div>
          </div>
        </div>
        <div style="height: 5rem;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { mapGetters } from 'vuex';
export default {
  name: "addWorker",
  components: {
    titlebar,
  },
  data() {
    return {
      palin_produce: false, // 普通 包装日期 
      palin_quality: false, // 普通 保质期 
      befor_produce: false, // 预包装 包装日期 
      befor_quality: false, // 预包装 保质期 
    };
  },
  async created() {
    this.page_name = this.$route.query.name || '';
    this.getInfo();
  },
  methods: {
    handleChange(name, type) {
      let query = {
        type: type,
        status: this[`${name}`] ? 1 : 0,
      }
      this.$api.setTagPrintSet(query).then(res => {
        if (res.code == 1) {
          this.$toast('设置成功，门店设置已更新');
          this.getInfo();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    getInfo() {
      this.$api.getTagPrintSet().then(res => {
        if (res.code == 1) {
          if (res.data.tag_print) {
            this.palin_produce = res.data.tag_print.palin_produce == 1;
            this.palin_quality = res.data.tag_print.palin_quality == 1;
            this.befor_produce = res.data.tag_print.befor_produce == 1;
            this.befor_quality = res.data.tag_print.befor_quality == 1;
          }
        } else {
          this.$toast(res.msg);
        }
      })
    },
    goBack() {
      this.$router.replace({
        name: 'featureSet',
        query: {
          name: 'featureSet',
          active: this.$route.query.active,
        },
      })
    },
  },
};
</script>

<style lang="less" scoped>

.desk-right {
  height: calc(100% - 10.6rem);
  background-color: #fff;
  margin-top: 1.5rem;

  .add-food {
    height: 100%;
    margin-top: 2rem;
    overflow: scroll;
    overflow-x: hidden;
    text-align: left;
    padding-left: 6rem;
  }
}

.label-preview-box {
  border: 0.15rem solid #afafaf;
  font-size: 2.2rem;
  padding: 1rem;
  border-radius: 0.8rem;
  margin-right: 10rem;
  width: 32rem;
  background: #fff;
}
.label-set-box {
  box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.5);
  background: #fff;
  font-size: 2.4rem;
  width: 45rem;
  border-radius: 0.8rem;
  padding-bottom: 0.5rem;

  .label-set-item {
    padding: 2.1rem 2rem;
    border-bottom: 0.1rem solid #e5e5e5;
  }
  .label-set-item:last-child {
    border-bottom: none;
  }
}

.con {
  height: 100%;
}
</style>
